/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import type { DataOptions } from "vuetify";
import type { Result, ApiMetaResponse } from "vue-mc";
import { clone, first, get, has, invoke, isNil, set } from "lodash";
import type { Ref } from "@/types/utils";
import { EventBus } from "@/services/event-bus";
import { isNavigationFailure, NavigationFailureType } from "vue-router";

@Component
export default class PaginateMixin extends Vue {
  @Inject() currentPageRef!: Ref<number>;
  @Inject() paginationRef!: Ref<Partial<DataOptions>>;
  /**
   * @type {Ref<boolean> | undefined} Set true to disable route pagination
   */
  @Inject() noParamPage!: Ref<boolean>;

  /**
   * @type {boolean} Set true to force local loading
   */
  bLocalLoading: boolean = false;
  /**
   * @type {boolean} Set true to force hard refresh
   */
  hardRefresh: boolean = false;
  /**
   * @type {number} Server items length
   */
  serverItemsLength: number = 0;
  /**
   * @type {number} Server last page
   */
  serverLastPage: number = 0;

  /**
   * @type {string} Method to invoke after paginate. Defaults 'index'
   */
  sMethod: string = "index";

  get paramPage() {
    return this.$route.query?.page ? Number(this.$route.query.page) : 1;
  }

  get currentPage(): number {
    return this.currentPageRef.value;
  }

  set currentPage(sValue: number | string) {
    this.currentPageRef.value = Number(sValue);
  }

  get pagination() {
    return this.paginationRef.value;
  }

  set pagination(sValue: Partial<DataOptions>) {
    // console.trace(sValue);
    this.paginationRef.value = sValue;
  }

  @Watch("pagination", { deep: true })
  onPagination(dataNew: Partial<DataOptions>) {
    if (this.hardRefresh && this.paramPage !== 1) {
      // this.routeToPage(1);
      this.hardRefresh = false;
      this.paginate(1);
      return;
    }

    if (isNil(dataNew) || !dataNew.page) {
      return;
    }

    const { page } = dataNew;

    if (this.currentPage === page && page === this.paramPage) {
      return;
    }

    /*console.log({
      paramPage: this.paramPage,
      page,
      query: this.$route.query,
      currentPage: this.currentPage,
      hardRefresh: this.hardRefresh,
    });*/

    if (page !== this.currentPage) {
      if (page !== this.paramPage) {
        this.routeToPage(page);
      }
      this.paginate(page);
      return;
    }

    if (page !== this.paramPage) {
      this.routeToPage(page);
    }

    this.setSortData(dataNew);
  }

  routeToPage(page: number) {
    if (this.paramPage === page || this.noParamPage.value) {
      return;
    }

    this.$router
      .replace({
        query: { ...this.$route.query, page: `${page}` },
      })
      .catch((e) => {
        if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
          return;
        }
      });
  }

  mapPagination(data: Result | ApiMetaResponse) {
    const paginationData: ApiMetaResponse | undefined = has(data, "meta")
      ? (get(data, "meta") as ApiMetaResponse | undefined)
      : (data as ApiMetaResponse);

    if (!paginationData) {
      return;
    }

    this.pagination = {
      ...this.pagination,
      page: paginationData.current_page,
      itemsPerPage: paginationData.per_page,
    };
    this.serverItemsLength = paginationData.total;
    this.serverLastPage = paginationData.last_page;
  }

  /**
   * Set sort data
   * @date 11/10/2022 - 09:35:57
   * @author Planeta del Este
   *
   * @param {DataOptions} obData
   */
  setSortData(obData: Partial<DataOptions>) {
    // Add sort data with filters
    if (!obData.sortBy?.length) {
      return;
    }

    const obSortData = {
      column: first(obData.sortBy),
      direction: "asc",
    };

    if (obData.sortDesc?.length && first(obData.sortDesc)) {
      obSortData.direction = "desc";
    }

    // Try to update model filters
    // @ts-ignore
    const obFilters: Record<string, any> = clone(get(this, "modelFilters", {}));
    set(obFilters, "sort", obSortData);
    invoke(this, "onSetFilters", obFilters);
  }

  setParam(sValue: any, sKey: keyof DataOptions) {
    this.pagination = { ...this.pagination, [sKey]: sValue };
  }

  onSortBy(sValue: string | string[]) {
    this.setParam(sValue, "sortBy");
  }

  onSortDesc(sValue: boolean | boolean[]) {
    this.setParam(sValue, "sortDesc");
  }

  onPage(sValue: number) {
    this.setParam(sValue, "page");
  }

  paginate(page: number) {
    this.currentPage = page;

    if (this.sMethod !== "index") {
      invoke(this, this.sMethod);
      return;
    }

    EventBus.emit("reload.index");
  }

  refreshPagination() {
    this.currentPage = 1;
    // this.hardRefresh = true;
    // this.setParam(1, "page");
    // this.onPagination(this.pagination);
    //TODO revisar por que no resetea la pagina
  }
}
